
import { blazorSvgLinksInit } from "./src/BlazorSvgLinks";
import { draw } from "./src/GraphDisplay";

const Elcome = {
    GraphDisplay: {
        draw,
    },
};

declare global {
    interface Window {
        Elcome: typeof Elcome;
    }
}

window.Elcome = Elcome;

blazorSvgLinksInit();
