

interface Node {
    id: string;
    tspans?: TSpan[];
    class?: string;
    rx?: string;
    ry?: string;
    paddingX: number;
    paddingY: number;
    marginX: number;
    marginY: number;
    href?: string;

    groupEl?: Element;
    width?: number;
    height?: number;
}

interface TSpan {
    attributes: AttributeValueDictionary;
    text?: string;
}

interface Edge {
    fromNode?: string;
    toNode?: string;
    class?: string;
}

interface AttributeValueDictionary {
    [id: string]: string | number | null | undefined;
}

// TODO: Proper types for dagre
declare global {
    interface Window { dagre: any; }
}

export const draw = (selector?: string, nodes?: Node[], edges?: Edge[]) => {

    const doc = document;
    const svgGroup = selector ? doc.querySelector(selector) : null;
    if (svgGroup instanceof SVGGraphicsElement) {

        const svg = svgGroup?.closest("svg");
        if (svg) {

            svgGroup.replaceChildren();

            if (nodes && edges) {

                let setAttributes = function (el: Element, attributes: AttributeValueDictionary) {
                    for (let attributeName of Object.keys(attributes)) {
                        let attributeValue = "" + attributes[attributeName];
                        if (attributeValue) {
                            el.setAttribute(attributeName, attributeValue);
                        }
                    }
                };

                let appendSvgNode = function <K extends keyof SVGElementTagNameMap>(parent: Element, tagName: K, attributes: AttributeValueDictionary, text?: string) {
                    let el = doc.createElementNS("http://www.w3.org/2000/svg", tagName);
                    if (attributes) setAttributes(el, attributes);
                    if (text) el.appendChild(doc.createTextNode(text));
                    parent.append(el);
                    return el;
                };

                nodes.forEach(function (node) {

                    let group = appendSvgNode(svgGroup, "g", { "class": "node " + node.class });
                    let groupInner = group;
                    if (node.href) {
                        groupInner = appendSvgNode(group, "a", { href: node.href });
                    }
                    let rect = appendSvgNode(groupInner, "rect", { x: node.marginX, y: node.marginY, rx: node.rx, ry: node.ry });
                    let text = appendSvgNode(groupInner, "text", {});

                    let tspans = node.tspans;
                    if (tspans) {
                        for (let tspanDef of tspans) {
                            let icon = appendSvgNode(text, "tspan", tspanDef.attributes, tspanDef.text);

                            let iconBeforeContent = getComputedStyle(icon, "::before").content;
                            if (iconBeforeContent) {
                                iconBeforeContent = iconBeforeContent.replace(/^'(.*)'$/, "\"$1\"");
                                try {
                                    icon.insertBefore(document.createTextNode(JSON.parse(iconBeforeContent)), icon.firstChild);
                                } catch { }
                            }
                        }
                    }

                    node.groupEl = group;

                    let box = text.getBBox();
                    node.width = box.width + 2 * node.paddingX + 2 * node.marginX;
                    node.height = box.height + 2 * node.paddingY + 2 * node.marginY;

                    setAttributes(text, { "transform": `translate(${node.paddingX + node.marginX - box.x},${node.paddingY + node.marginY - box.y})` });
                    setAttributes(rect, { "width": box.width + 2 * node.paddingX, "height": box.height + 2 * node.paddingY });
                });

                const dagre = window.dagre;

                let g = new dagre.graphlib.Graph()
                    .setGraph({})
                    .setDefaultEdgeLabel(function () { return {}; });

                for (let node of nodes) {
                    g.setNode(node["id"], node);
                }

                for (let edge of edges) {
                    g.setEdge(edge["fromNode"], edge["toNode"], edge);
                }

                dagre.layout(g);

                g.nodes().forEach(function (v: any) {

                    let node = g.node(v);

                    node.groupEl.setAttribute("transform", `translate(${node.x - node.width / 2},${node.y - node.height / 2})`);

                });
                g.edges().forEach(function (e: any) {

                    let edge = g.edge(e);
                    let group = appendSvgNode(svgGroup, "g", { "class": "edge " + edge.class });
                    let path = appendSvgNode(group, "path", { "d": `M${edge.points.map((c: any) => `${c.x},${c.y}`).join("L")}`, "marker-end": "#arrowhead" });

                });

                let box = svgGroup.getBBox();

                let svgMargin = 2;
                box.x -= svgMargin;
                box.y -= svgMargin;
                box.width += 2 * svgMargin;
                box.height += 2 * svgMargin;

                setAttributes(svg, {
                    "viewBox": `${box.x} ${box.y} ${box.width} ${box.height}`,
                    "width": box.width,
                    "height": box.height,
                });

            }
        }
    }
};

