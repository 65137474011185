// By default, clicking an <a href> within an SVG will force navigate, losing any state stored in blazor etc
// So capture the clicks and call Blazor.navigateTo() to work around this

import "./BlazorGlobal";

export const blazorSvgLinksInit = function () {
    
    document.addEventListener("click", function (event) {

        // Don"t stop ctrl-click/middle-click etc from opening links in new tabs/windows
        if (event.button === 0 && !event.ctrlKey && !event.shiftKey && !event.altKey && !event.metaKey && !event.defaultPrevented) {

            // Find the containing svg link element
            // TODO: Consider using composedPath() if available
            let el = event.target as HTMLElement | null;
            while (el) {
                if (el.tagName === "a" && el.namespaceURI === "http://www.w3.org/2000/svg") {
                    break;
                }
                el = el.parentElement;
            }

            if (el) {
                const targetAttributeValue = el.getAttribute("target");
                const opensInSameFrame = !targetAttributeValue || targetAttributeValue === "_self";
                const canProcessAnchor = opensInSameFrame && el.hasAttribute("href") && !el.hasAttribute("download");

                if (canProcessAnchor) {

                    event.preventDefault();

                    const href = el.getAttribute("href");
                    if (href) {
                        window.Blazor.navigateTo(href, {});
                    }
                }
            }
        }
    });
};
